<template>    
  <div>
    <v-container fluid>
      <v-toolbar flat dense class="tc-title">
            <v-toolbar-title>Approve Question</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
        </v-toolbar>
    <v-card>
      <v-card-text>
        <v-row align="center">
          <v-col class="d-flex" cols="12" sm="3">
            <v-select
              :items="company_list"
              item-value="id"
              item-text="name"
              label="Choose Company"
              v-model="selected_company"
              ></v-select>
          </v-col>
          <v-col class="d-flex" cols="12" sm="3">
            <v-select
                :items="round_list"
                item-value="id"
                item-text="name"
                label="Round"
                v-model="selected_round"
              ></v-select>
          </v-col>

          <v-col class="d-flex" cols="12" sm="3">
            <v-select
              :items="type_list"
              item-value="id"
              item-text="name"
              v-model="selected_type"
              label="Question Type"
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="12" sm="3">
            <v-btn color="primary" @click="featch" class="mb-2 btn-title" >PROCEED</v-btn>
            
       
          </v-col>
      </v-row>
    </v-card-text>
    </v-card> 
    <template v-if="q_list!=null">
            <v-toolbar flat dense class="tc-title">
                <v-toolbar-title>Question</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
            </v-toolbar>
            <template>
                <v-data-table
                    :headers="headers"
                    :items="q_list[0]"
                    multi-sort
                    class="elevation-1"
                >
                <template v-slot:item.option_list="{item}">
                    <span v-if="item.question_type=='Descriptive'" style="color:green">
                        <v-icon style="color:green">mdi-checkbox-marked-circle</v-icon> {{item.question_answer}}
                    </span>
                    <span v-else-if="item.question_type=='Image'">
                        <a v-bind:href="item.quefile" target="blank" class="alink"><v-icon color="success" >mdi-cloud-download</v-icon></a>
                    </span>
                    <span v-else>
                        <span v-for="item in item.option_list" :key="item">
                                <span v-if="item.iscorrect" style="color:green">
                                    <v-icon style="color:green">mdi-checkbox-marked-circle</v-icon> {{item.option_value}}
                                </span>
                                <span v-else style="color:red">
                                <v-icon style="color:red">mdi-close-circle</v-icon> {{item.option_value}}
                                </span>
                                <br>
                        </span>
                    </span>    
                </template>
                <!--<template v-slot:item.isapprove="{item}">
                        <span v-if="item.isapprove" style="color:green">
                          <v-select
                            :items="is_approve"
                            item-value="id"
                            item-text="value"
                            v-model="item.isapprove"
                             label="Is Approve"
                             @change="isapproved(item)"
                            ></v-select>
                        </span>
                        <span v-else>
                           <v-select
                            :items="is_approve"
                            item-value="id"
                            item-text="value"
                            v-model="item.isapprove"
                            label="Is Approve"
                            @change="isapproved(item)"
                            ></v-select>
                        </span>
                </template>-->
                <template v-slot:item.isapprove="{item}">
                        <span v-if="item.isapprove" style="color:green">
                         <v-checkbox v-model="item.isapprove" class="mx-2" @click="isapproved(item)" label="Approve"></v-checkbox>
                        </span>
                        <span v-else>
                           <v-checkbox v-model="item.isapprove" class="mx-2" @click="isapproved(item)" label="Not Approve"></v-checkbox>
                        </span>
                </template>
                <template v-slot:item.isdelete="{item}">
                        <span v-if="item.isdelete" style="color:green">
                           <v-icon style="color:green" @click="isdeleted(item)">mdi-checkbox-marked-circle</v-icon> 
                        </span>
                        <span v-else style="color:red">
                            <v-icon style="color:red" @click="isdeleted(item)">mdi-delete</v-icon>
                        </span>
                </template>
                <template v-slot:item.quefile="{item}">
                        <span v-if="item.quefile" style="color:green">
                           <a v-bind:href="item.quefile" target="blank" class="alink"><v-icon color="success" >mdi-cloud-download</v-icon></a>
                        </span>
                        <span v-else>
                            NA
                        </span>
                </template>
                <template v-slot:item.isedit="{item}">
              
                            <!-- <v-icon color="indigo" @click="editItem(item)">mdi-pencil-circle</v-icon> -->
                </template>
                </v-data-table>
            </template>
    </template>
    </v-container>     
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    headers: [
          { text: 'Date', value: 'date' },
          { text: 'Company', value: 'question_company' },
          { text: 'Round', value: 'question_company_round' },
          { text: 'Question Type', value: 'question_type' },
          { text: 'Question', value: 'question_stmt' },
          { text: 'Option', value: 'option_list' },
          { text: 'Question Image', value: 'quefile' },
          { text: 'Approve', value: 'isapprove', width:'10px' },
          // { text: 'Edit', value: 'isedit' },
          { text: 'Is Delete', value: 'isdelete' },
        ],
    is_approve:[
        {'id':true,'value':'Approved'},{'id':false,'value':'Not Approved'}
    ],    
    snackbar_msg: "",
    snackbar: true,
    color: "",
    search:'',
    selected_company:'',
    selected_round:'',
    selected_type:'',
    q_list:null,
    del:null,
    app:null
  }),
  methods: {
    isapproved(item){
      //console.log(item)
        //alert("Is Approve :"+item.isapprove )
        if(item.isapprove==false)
            {
                 this.app=true
            }else{
                  this.app=false
            }
        const data={
           q_id:item.id,
           is_approve:this.app
        };
         axios.post("/LearnerData/ApproveQuestion",data)
        .then(res=>{
                this.featch()
        })
    },  
     isdeleted(item){
        //alert("Is Approve :"+item.isdelete )
        if(item.isdelete==false)
            {
                 this.del=true
            }else{
                  this.del=false
            }
        const data={
            q_id:item.id,
           is_delete:this.del
        };
         axios.post("/LearnerData/isdeletedQuestion",data)
        .then(res=>{
                this.featch()
        })
    },  
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    featch(){
        //alert("Featch :data --"+this.selected_company)
        const data = {
            company:this.selected_company,
            round:this.selected_round,
            qtype:this.selected_type,
        };
        //alert("Data :"+data)
        axios.post("/LearnerData/featchApproveQuestion",data)
        .then(res=>{
                this.q_list=res.data.question_list
                //console.log(this.q_list)
                this.onload()
        })
    },
    onload(){
        axios
      .post("/LearnerData/approveHome")
      .then(res => {
          this.company_list = res.data.company_list
          this.round_list = res.data.company_round_list
          this.type_list = res.data.question_type_list
          this.status_list = res.data.question_status_list
          
          this.company_list.push({
                    name: 'ALL',
                    text: ''
          })
          this.round_list.push({
                    name: 'ALL',
                    text: ''
          })
          this.type_list.push({
                    name: 'ALL',
                    text: ''
          })
          
          this.selected_company="ALL"
          this.selected_round="ALL"
          this.selected_type="ALL"
          this.selected_status="ALL"
      })
      .catch(error => {
        this.showSnackbar("#b71c1c", "Something went wrong!!!");
      })
      .finally(() => {

      });
    }
  },
 
  mounted() {
    this.onload()
  }
};
</script>
<style scoped>

.nav-arrow-left{
        margin-right: 5px;
        text-decoration: none;
        color: #FFF;
    }
    .nav-arrow-right{
        margin: 5px;
        text-decoration: none;
        color: rgb(3, 55, 167);
    }
    .left-arrow{
        color: #FFF;
    }
    .right-arrow{
        color: rgb(3, 55, 167);
    }
.ec-title{
        background-image: linear-gradient(-100deg, #f4faba, #d64907);
        color: #FFF;
    }
    .c-title{
        background-image: linear-gradient(-90deg, #befcf7, #03948d);
        color: #FFF;
    }
    .tc-title{
        background-image: linear-gradient(-90deg, #FFF, #057996);
        color: #FFF;
        border-radius: 3px;
    }
    .btn-title{
        background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
        color: #FFF;
    }
    .card-title{
         background-image: linear-gradient(-90deg, #8edffd, #034c70);
        color: #FFF;
    }
    .del-card-title{
         background-image: linear-gradient(-90deg, #fde7e7, #ac0404);
        color: #FFF;
    }
    .lbl{
        color: #1705e6;
    }
    .del{
    background-image: linear-gradient(-0deg, #f4faba, #ff0707);
color: #FFF;
}
</style>
